<template>
    <a-drawer
            title="Thông tin sản phẩm"
            :width="800"
            @close="() => { $emit('close')}"
            :visible="visible"
            :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
    >
        <div v-if="product">
            <a-row :gutter="16">
                <a-col :span="14">
                    <div id="product-images">
                        <a-carousel arrows dotsClass="slick-dots slick-thumb">
                            <a slot="customPaging" slot-scope="props">
                                <img :src="product.images[props.i].url" alt="">
                            </a>
                            <div v-for="image in product.images" :key="image.id">
                                <img style="width: 300px;" :src="image.url" />
                            </div>
                        </a-carousel>
                    </div>
                </a-col>
                <a-col :span="10">
                    <h2>{{ product.name }}</h2>
                    <a-divider />
                    <a-row style="margin-bottom: 5px;">
                        <strong style="margin-right: 5px;"> Giá: </strong><span style="color: red; font-weight: bold">{{product.price}}đ</span>
                    </a-row>
                    <a-row style="margin-bottom: 5px;">
                        <strong style="margin-right: 5px;"> Xuất xứ:</strong> {{product.country}}
                    </a-row>
                    <a-row style="margin-bottom: 5px;">
                        <strong style="margin-right: 5px;"> Thương hiệu:</strong> {{product.brand}}
                    </a-row>
                    <a-row v-if="product.category" style="margin-bottom: 5px;">
                        <strong style="margin-right: 5px;"> Danh mục:</strong> {{product.category.name}}
                    </a-row>
                    <a-row v-for="attr in product.productAttributes" style="margin-bottom: 5px;" :key="attr.id">
                        <strong style="margin-right: 5px;">{{attr.name}}:</strong>
                        <a-tag v-for="value in attr.productAttributeValues" :key="value.id" color="cyan">{{value.value}}</a-tag>
                    </a-row>
                    <a-row style="margin-bottom: 5px;">
                        <strong style="margin-bottom: 5px; display: block;">Thẻ:</strong>
                        <div>
                            <a-tag v-for="tag in product.tags" color="cyan" :key="tag.id">{{tag.name}}</a-tag>
                        </div>
                    </a-row>
                </a-col>
            </a-row>
            <a-row style="margin-top: 50px; padding: 20px;">
                <a-tabs defaultActiveKey="1" @change="callback">
                    <a-tab-pane tab="Mô tả ngắn" key="1" v-html="product.shortDescription"></a-tab-pane>
                    <a-tab-pane tab="Mô tả chi tiết" key="2" v-html="product.fullDescription"></a-tab-pane>
                    <a-tab-pane tab="Hướng dẫn sử dụng" key="3" v-html="product.manual"></a-tab-pane>
                </a-tabs>
            </a-row>
        </div>
    </a-drawer>
</template>
<script>
    export default {
        name: 'ProductDetails',
        props: ['visible', 'product']
    };
</script>
<style scoped>
    /* For demo */
    #product-images .ant-carousel >>> .slick-dots {
        height: auto;
    }
    #product-images .ant-carousel >>> .slick-slide img {
        border: 5px solid #fff;
        display: block;
        margin: auto;
        max-width: 80%;
    }
    #product-images .ant-carousel >>> .slick-thumb {
        bottom: -45px;
    }
    #product-images .ant-carousel >>> .slick-thumb li {
        width: 60px;
        height: 45px;
    }
    #product-images .ant-carousel >>> .slick-thumb li img {
        width: 100%;
        height: 100%;
        filter: grayscale(100%);
    }
    #product-images .ant-carousel >>> .slick-thumb li.slick-active img {
        filter: grayscale(0%);
    }
</style>