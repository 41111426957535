<template>
    <a-layout-content class="Question">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <a-layout :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px', padding: '15px' }">
            <a-layout-sider width="200" style="background: #fff">
                <a-menu
                        mode="inline"
                        :defaultSelectedKeys="[this.$route.path]"
                        :defaultOpenKeys="[this.$route.path]"
                        style="height: 100%"
                        @click="groupFilterClick"
                >
                    <a-menu-item key="0" style="margin-bottom: 20px">
                        <h2>Danh mục</h2>
                    </a-menu-item>
                    <a-menu-item key="/products">
                        <router-link :to="{ path: '/products'}" class="nav-link">
                            <a-icon type="appstore" />
                            <span>Tất cả</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item v-for="category in categories" :key="'/products/category/' + category.id">
                        <router-link :to="{ path: '/products/category/' + category.id }" class="nav-link">
                            <a-avatar shape="square" size="small" :src="category.featuredImage.url" />
                            <span style="margin-left: 10px">{{ category.name }}</span>
                        </router-link>
                    </a-menu-item>
                </a-menu>
            </a-layout-sider>
            <a-layout-content :style="{ padding: '0 24px' }">
                <div class="content-header">
                    <div class="content-header__right">
                        <h2>Danh sách sản phẩm</h2>
                    </div>
                    <div class="content-header__left">
                        <a-select :defaultValue="searchType" style="width: 70px; margin-left: 30px" @change="handleSearchTypeChange">
                            <a-select-option value="key">Tên</a-select-option>
                            <a-select-option value="id">ID</a-select-option>
                        </a-select>
                        <a-input-search
                                :placeholder="searchPlaceHolder"
                                style="width: 300px; margin-left: 3px"
                                @change="(e) => {this.selectedRowKeys = [];this.search = e.target.value}"
                        />
                    </div>
                </div>
                <a-divider />
                <a-table
                        :columns="columns"
                        :rowKey="record => record.id"
                        :dataSource="productFilter"
                        :pagination="true"
                        :loading="loading"
                        bordered
                >
                    <template slot="name" slot-scope="name">
                        {{name.first}} {{name.last}}
                    </template>
                    <template slot="price" slot-scope="text, record">
                        {{numberWithCommas(record.price)}}đ
                    </template>
                    <template slot="category" slot-scope="text">
                        {{text ? text.name:"" }}
                    </template>
                    <template style="text-align: center" slot="featuredImage" slot-scope="text">
                        <a-avatar shape="square"  size="large" :src="text.url" />
                    </template>
                    <template style="text-align: center" slot="active" slot-scope="text, record">
                        <div style="text-align: center; display: flex; flex-direction: row; justify-content: center;">
                            <a-popconfirm title='Bạn có chắc chắn muốn thay đổi?' @confirm="() =>  handleActiveChange(record)">
                                <a-checkbox :checked="text"/>
                            </a-popconfirm>
                        </div>
                    </template>
                    <template slot="action" slot-scope="text, record">
                        <div>
                            <a-button @click="() => handleViewDetail(record)" icon="eye" type="primary" style="margin: 5px;" />
                            <a-button @click="() => handleEdit(record)" icon="edit" type="default" style="margin: 5px;"/>
                        </div>
                    </template>
                </a-table>
                <product-details :visible="visible" :product="productView" @close="onDrawerClose" />
            </a-layout-content>
        </a-layout>
    </a-layout-content>
</template>

<script>
    import {RepositoryFactory} from "@/repository/RepositoryFactory";
    import { removeUnicodeChar } from "@/util/StringUtils";
    import ProductDetails from "../../components/drawer/ProductDetails";
    const categoryRepository = RepositoryFactory.get("category");
    const productRepository = RepositoryFactory.get("product");

    export default {
        name: "ProductList",
        components: { ProductDetails },
        data() {
            return {
                categories: [],
                products: [],
                search: '',
                productView: null,
                currentPage: 1,
                loading: false,
                visible: false,
                searchType: "id",
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        key: 'id',
                        width: '5%'
                    },
                    {
                        title: 'Hình ảnh',
                        dataIndex: 'featuredImage',
                        key: 'featuredImage',
                        width: '10%',
                        scopedSlots: { customRender: 'featuredImage' },
                    },
                    {
                        title: 'Tên sản phẩm',
                        dataIndex: 'name',
                        key: 'name',
                        width: '40%'
                    },
                    {
                        title: 'Giá sản phẩm',
                        dataIndex: 'price',
                        key: 'price',
                        width: '10%',
                        scopedSlots: { customRender: 'price' },
                    },
                    {
                        title: 'Danh mục',
                        dataIndex: 'category',
                        key: 'category',
                        width: '15%',
                        scopedSlots: { customRender: 'category' },
                    },
                    {
                        title: 'Active',
                        dataIndex: 'active',
                        key: 'active',
                        width: '5%',
                        scopedSlots: { customRender: 'active' },
                        filters: [{ text: 'Kích hoạt', value: 'true' }, { text: 'Không kích hoạt', value: 'false' }],
                        onFilter: (value, record) => record.active.toString() === value
                    },
                    {
                        title: 'Action',
                        width: '20%',
                        scopedSlots: { customRender: 'action' },
                    }
                ]
            }
        },
        methods: {
            getProducts: async function ({category}) {
                let productResponse = {};
                if (category) {
                    productResponse = await categoryRepository.getProductsMin({categoryId: category.id});
                } else {
                    productResponse = await productRepository.getAll();
                }
                if (!productResponse.data.meta.success) throw new Error("Question data" + productResponse.data.meta.externalMessage);
                this.products = productResponse.data.data.sort( (a,b) => b.id - a.id);
            },
            groupFilterClick: async function ({key}) {
                try {
                    this.loading = true;
                    this.currentPage = 1;
                    // eslint-disable-next-line no-console

                    if (key === 'all' || !this.$route.params.categoryId) await this.getProducts({});
                    if (this.$route.params.categoryId) await this.getProducts({category: { id: this.$route.params.categoryId}})
                } catch (e) {
                    await this.$message.error(e.message);
                } finally {
                    this.loading = false;
                }
            },
            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            async handleDeleteProduct(product) {
                try {
                    this.loading = true;
                    let { data } = await productRepository.deleteProduct({ productId: product.id });
                    if (!data.meta.success) throw new Error("Xảy ra lỗi khi xóa sản phẩm, vui lòng thử lại!");

                    this.products = this.products.filter( p => p.id !== product.id);
                } catch (e) {
                    await this.$message.error(e.message);
                } finally {
                    this.loading = false;
                }
            },
            handleChangeOnActive (value, id, column) {
                const newData = [...this.products];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target[column] = value;
                    this.products = newData;
                }
            },
            async handleUpdateProduct(product) {
                try {
                    this.loading = true;
                    let { data } = await productRepository.updateProductById({ id: product.id, product: product });
                    if (!data.meta.success) throw new Error("Xảy ra lỗi khi cập nhật sản phẩm, vui lòng thử lại!");
                } catch (e) {
                    await this.$message.error(e.message);
                } finally {
                    this.loading = false;
                }
            },
            async fetchProductViewDetails(id) {
                try {
                    this.loading = true;
                    let { data } = await productRepository.getProductById({id});
                    if (!data.meta.success) throw new Error("Không thể lấy thông tin sản phẩm!");
                    this.productView = data.data;
                    this.productView.images = [this.productView.featuredImage, ...this.productView.images];
                    this.visible = true;
                } catch (e) {
                    await this.$message.error(e.message);
                } finally {
                    this.loading = false;
                }
            },
            handleViewDetail(product) {
                // eslint-disable-next-line no-console
                console.log("onView")
                this.fetchProductViewDetails(product.id);
            },
            handleEdit(product) {
                this.$router.push({ name: 'product-form', params: { product } })
            },
            onDrawerClose() {
                this.visible = false;
            },
            async handleActiveChange(product) {
                product.active = !product.active;
                await this.handleUpdateProduct(product);
                this.handleChangeOnActive(product.active, product.id, 'active')
            },
            handleSearchTypeChange(value) {
                this.search = '';
                this.searchType = value;
            },
        },
        computed: {
            productFilter: function() {
                if (this.searchType === 'key') {
                    return this.products.map(item => Object.assign({}, item))
                        .filter(product => product.name && removeUnicodeChar(product.name).toUpperCase().includes(removeUnicodeChar(this.search).toUpperCase()))
                }
                if (this.search.length === 0) {
                    return this.products;
                }
                let ids = this.search.split(",");
                return this.products.filter(product => ids.includes(product.id.toString()) || ids.includes(product.haravanId.toString()));
            },
            searchPlaceHolder() {
                return this.searchType === 'id' ? 'Danh sách id cách nhau bởi dấu phẩy...':'Nhập tên sản phẩm để tìm kiếm...'
            }
        },
        async created() {
            try {
                this.loading = true;
                let { data } = await categoryRepository.getAll();
                if(!data.meta.success) throw new Error(data.meta.externalMessage)
                this.categories = data.data;
                if (this.$route.params.categoryId) {
                    await this.getProducts({category: { id: this.$route.params.categoryId}})
                } else {
                    await this.getProducts({})
                }
            } catch (e) {
                await this.$message.error(e.message);
            } finally {
                this.loading = false;
            }
        }
    };
</script>

<style scoped>
    .content-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>
